













































































import { Component, Inject, Vue } from "vue-property-decorator";
import { BackendClient, Task, TaskStatus } from "ibf-backend";

import LogViewer from "@femessage/log-viewer";
import moment from "moment";
import TaskStatusChip from "./TaskStatusChip.vue";

@Component({
  components: {
    TaskStatusChip,
    LogViewer,
  },
})
export default class TaskComponent extends Vue {
  @Inject("backendClient") client!: BackendClient;

  public task: Task | null = null;
  public logs: string = "";
  private isLoading = true;
  private isDowloading = false;
  private taskId: number = -1;
  private taskIsFinished = false;
  private taskIsRunning = false;
  private logComplete = false;

  private updateTaskStatusTimer: any = null;
  private updateLogsTimer: any = null;

  private readedLine = 0;

  mounted() {
    this.taskId = Number.parseInt(this.$route.params.id);
    this.updateTaskStatusTimer = setInterval(() => {
      this.updateTask();
    }, 1000);
    this.updateTask();

    this.updateLogsTimer = setInterval(() => {
      this.readNextLogs();
    }, 1000);
    this.readNextLogs();
  }

  beforeDestroy() {
    clearInterval(this.updateTaskStatusTimer);
    clearInterval(this.updateLogsTimer);
  }

  async updateTask() {
    this.task = await this.client.taskApi.getTask(this.taskId);

    if (this.task != null) {
      this.taskIsFinished = this.isTaskFinished();
      this.taskIsRunning = this.isTaskRunning();

      if (this.taskIsFinished) {
        clearInterval(this.updateTaskStatusTimer);
      }
    }
  }

  private isSucess() {
    return TaskStatus[String(this.task?.status)] === TaskStatus.SUCCESS;
  }

  private isTaskFinished(): boolean {
    return (
      this.task != null &&
      TaskStatus[String(this.task?.status)] != TaskStatus.PENDING &&
      TaskStatus[String(this.task?.status)] != TaskStatus.RUNNING
    );
  }

  private isTaskRunning(): boolean {
    return (
      this.task != null &&
      TaskStatus[String(this.task?.status)] == TaskStatus.RUNNING
    );
  }

  async readNextLogs() {
    if (this.task != null && this.task.status != TaskStatus.PENDING) {
      try {
        let nextLogs = await this.client.taskApi.getLog(
          this.task.id,
          this.readedLine,
          100
        );

        if (nextLogs.length == 0 && this.isTaskFinished()) {
          clearInterval(this.updateLogsTimer);
          this.logComplete = true;
        }

        this.readedLine += nextLogs.length;
        this.logs += "\n" + nextLogs.join("\n");
      } catch (err) {
        this.logs = "No logs found";
      }
    }
  }

  canShowFile(fileName: string) {
    return (
      fileName != null &&
      (fileName?.endsWith(".html") || fileName?.endsWith(".psr"))
    );
  }

  async showFile(fileName: string) {
    if (fileName?.endsWith(".html")) {
      this.isDowloading = true;
      const blob = await this.client.taskApi.getResultFile(
        this.task!.id,
        fileName
      );

      window.open(
        window.URL.createObjectURL(blob),
        "_blank" // <- This is what makes it open in a new window.
      );

      this.isDowloading = false;
    }

    if (fileName?.endsWith(".psr")) {
      // switch page
      this.$router.push({ path: `/psr/task/${this.task?.id}/${fileName}` });
    }
  }

  async downloadFile(fileName: string) {
    this.isDowloading = true;
    const blob = await this.client.taskApi.getResultFile(
      this.task!.id,
      fileName
    );

    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName.slice(fileName.lastIndexOf("/") + 1);
    link.click();

    this.isDowloading = false;
  }

  getDuration() {
    const startMoment = moment(this.task!.startTime);
    const endMoment = moment(this.task!.endTime);
    const duration = moment.duration(endMoment.diff(startMoment));
    return moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
  }

  getExitMessage() {
    return this.task?.output;
  }

  stop() {
    this.client.taskApi.stopTask(this.taskId);
  }
}
